import DataSourcesApi from '../api/dataSourcesApi';
import LicensesApi from '../api/licensesApi';
import PackagesApi from '../api/packagesApi';
import React, { Fragment, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Logo from '../assets/img/logo.png';
import StripeLogo from '../assets/img/powered_by_stripe.png'
import IDataSource from '../models/platform/dataSource';
import { useHistory } from 'react-router-dom';
import { handleAxiosError } from '../utils/commonUtils';
import { SuccessResponse } from '../models/system/successResponse';
import IPackage from '../models/platform/package';


export default function Subscribe(props: {
    handlePageChange: (path: string) => void;
    getProfile: () => Promise<void>;
}) {
    const [open, setOpen] = useState(false);
    const [buyProcess, setBuyProcess] = useState(false);
    const [dataSources, setDataSources] = useState<IDataSource[]>([]);
    const [subscription, setSubscription] = useState<IPackage | null>(null);

    const history = useHistory();

    const buyPackage = async (package_id: string) => {
        try {
            let redirect_url = window.location.origin + '/licenses';
            let response = await LicensesApi.buyLicense(package_id, redirect_url);

            if (response.success) {
                const data = (response as SuccessResponse).data;

                setOpen(false);
                window.location.href = data.url;
            }
            else {
                toast.error(response.message);
            }
        }
        catch (error: any) {
            toast.error(handleAxiosError(error).message);
        }
    }

    const getCorePackage = async () => {
        try {
            const response = await PackagesApi.getCorePackage();

            if (response.success) {
                const data = (response as SuccessResponse).data as IPackage;
                setSubscription(data);
            }
            else {
                toast.error(response.message);
            }
        }
        catch (error: any) {
            toast.error(handleAxiosError(error).message);
        }
    }

    const getDataSources = async () => {
        try {
            const response = await DataSourcesApi.getAllDataSources();

            if (response.success) {
                const data = (response as SuccessResponse).data as IDataSource[];
                setDataSources(data);
            }
            else {
                toast.error(response.message);
            }
        }
        catch (error: any) {
            toast.error(handleAxiosError(error).message);
        }
    }

    useEffect(() => {
        try {
            getDataSources();
            getCorePackage();
        }
        catch (error: any) {
            toast.error(handleAxiosError(error).message);
        }
    }, []);

    useEffect(() => {
        // props.handlePageChange(location.pathname);
    }, []);


    return (
        <div style={{ backgroundColor: '#F0F8FF', minHeight: '100vh', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', padding: '30px' }}>
            <div style={{ margin: '10px 0', marginBottom: '20px', display: 'flex', alignItems: 'flex-start' }}>
                <img
                    src={Logo}
                    alt="Company Logo"
                    style={{ width: '400px', height: '66px', marginRight: '10px' }}
                />
            </div>
            <div style={{ width: '90%', display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between' }}>
                <div style={{ flex: 2, textAlign: 'left', padding: '20px', marginRight: '80px' }}>
                    <div style={{ fontSize: '50px', marginBottom: '20px' }}>
                        Unlock the Power of Data-driven Decisions
                    </div>
                    <div style={{ fontSize: '24px', color: '#777', marginTop: '10px', marginBottom: '50px' }}>Access a world of insights from leading data sources.  Purchase the monthly subscription and start accessing data today.</div>
                    <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(100px, 1fr))', gap: '10px', marginTop: '20px' }}>
                        {/* Loop through your 15 data sources and create a card for each */}
                        {dataSources.map((source, index) => (
                            <div key={index} style={{ backgroundColor: '#f9f9f9', borderRadius: '10px', padding: '10px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', height: '100%' }}>
                                <img
                                    src={source.logo_url}
                                    alt={source.name}
                                    style={{ maxWidth: '50%', maxHeight: '50%', marginBottom: '5px' }}
                                />
                                <div style={{ fontSize: '14px', fontWeight: 'normal', textAlign: 'center', flex: 1 }}>{source.name}</div>
                            </div>
                        ))}
                    </div>
                </div>
                <div style={{ flex: 1, textAlign: 'right', padding: '5px', height: 'calc(65vh - 70px)', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                    <div style={{ backgroundColor: '#FFFFFF', borderRadius: '10px', overflow: 'hidden' }}>
                        <div style={{ padding: '20px', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                            <div style={{ fontSize: '18px', color: '#777' }}>From USD</div>
                            <div style={{ fontSize: '34px' }}>
                                <span style={{ fontWeight: 'normal' }}>
                                    ${subscription?.stripe?.amount ? `${(subscription.stripe.amount / 100).toFixed(2)}` : 'N/A'}
                                </span>
                                <span style={{ fontSize: '16px', fontWeight: 'bold', marginLeft: '4px' }}>
                                    /month
                                </span>
                            </div>
                        </div>
                        <div style={{ padding: '20px', textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center', flex: 1 }}>
                            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="green">
                                    <path d="M0 0h24v24H0z" fill="none" />
                                    <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.42L9 16.17z" />
                                </svg>
                                <div style={{ marginLeft: '10px' }}>Top Quality Data</div>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="green">
                                    <path d="M0 0h24v24H0z" fill="none" />
                                    <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.42L9 16.17z" />
                                </svg>
                                <div style={{ marginLeft: '10px' }}>Unlimited data sources</div>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="green">
                                    <path d="M0 0h24v24H0z" fill="none" />
                                    <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.42L9 16.17z" />
                                </svg>
                                <div style={{ marginLeft: '10px' }}>Unlimited accounts</div>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="green">
                                    <path d="M0 0h24v24H0z" fill="none" />
                                    <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7L9 16.17z" />
                                </svg>
                                <div style={{ marginLeft: '10px' }}>Team capability</div>
                            </div>
                        </div>
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '0px' }}>
                        <div style={{ paddingTop: '0px', paddingBottom: '5px', textAlign: 'center' }}>
                            <button
                                onClick={async () => {
                                    setBuyProcess(true);
                                    try {
                                        await buyPackage(subscription?._id || '');
                                    } catch (err) {
                                        setBuyProcess(false);
                                    }
                                }}
                                style={{ padding: '10px 30px', fontSize: '28px', backgroundColor: '#4CAF50', color: '#fff', border: 'none', cursor: 'pointer', borderRadius: '10px' }}>
                                Purchase Subscription
                            </button>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', marginLeft: '5px', marginBottom: '10px' }}>
                            <img src={StripeLogo} alt="Powered by Stripe" style={{ width: '160px', height: '40px' }} />
                        </div>
                    </div>

                    <div></div>

                    <div style={{ marginTop: '20px', textAlign: 'center' }}>
                        <button
                            onClick={() => {
                                // Use history.goBack() to navigate back
                                history.goBack();
                            }}
                            style={{ fontSize: '18px', backgroundColor: '#0074cc', color: '#fff', border: 'none', cursor: 'pointer', padding: '10px 20px', borderRadius: '5px' }}>
                            Return to Dashboard
                        </button>
                    </div>
                </div>

            </div>
        </div>
    );

}




