import axios from 'axios';
import authHeader from '../utils/authHeaderUtils';
import { handleAxiosError } from '../utils/commonUtils';
import { SuccessResponse } from '../models/system/successResponse';


const API_URL = process.env.REACT_APP_BASE_URL;

class PackagesApi {

  async getCorePackage() {
    try {
      const response = await axios.get(`${API_URL}/package/subscription`, {
        headers: authHeader(),
      });

      return response.data as SuccessResponse;
    }
    catch (error) {
      return handleAxiosError(error);
    }
  }

}

export default new PackagesApi();
