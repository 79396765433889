import axios, { AxiosError } from 'axios';
import authHeader from '../utils/authHeaderUtils';
import { SuccessResponse } from '../models/system/successResponse';
import { handleAxiosError } from '../utils/commonUtils';


const API_URL = process.env.REACT_APP_BASE_URL;

class LicensesApi {

  async buyLicense(package_id: string, redirect_url: string) {
    try {
      const response = await axios.get(
        `${API_URL}/licenses/buy?package_id=${package_id}&redirect_url=${redirect_url}`,
        {
          headers: authHeader(),
        }
      );

      return response.data as SuccessResponse;
    }
    catch (error) {
      return handleAxiosError(error);
    }
  }

  async turnAutoRenewOff(license_id: string) {
    try {
      const response = await axios.put(`${API_URL}/licenses/${license_id}/auto-renew-off`, {}, {
        headers: authHeader(),
      });

      return response.data as SuccessResponse;
    }
    catch (error) {
      return handleAxiosError(error);
    }
  }

  async turnAutoRenewOn(license_id: string) {
    try {
      const response = await axios.put(`${API_URL}/licenses/${license_id}/auto-renew-on`, {}, {
        headers: authHeader(),
      });
      return response.data as SuccessResponse;
    }
    catch (error) {
      return handleAxiosError(error);
    }
  }

}

export default new LicensesApi();
